import moment from "moment/moment";
import { getBookingPrice, getTimezoneOffsetHours } from "@/helpers/utils";

export const getBookingsAffiliatesTableColumns = (venue) => {
  const ALL_COLUMNS = [
    {
      label: "ID",
      field: "id",
      id: "id",
      isHidden: false,
    },
    {
      label: "Booking date",
      field: "date",
      id: "date",
      formatFn: (date) => {
        const dateMoment = moment.utc(date.seconds || date._seconds, "X");
        return dateMoment
          .add(getTimezoneOffsetHours(venue.timezone), "h")
          .format("MMM D, YYYY");
      },
      // sortable: true,
      // sortFn: () => {},
      isHidden: false,
    },
    {
      label: "Paid amount",
      id: "paidAmount",
      field: (booking) => {
        const price = getBookingPrice(booking?.checkoutInfo);
        return (price?.paid || 0) / 100;
      },
      isHidden: false,
    },
    {
      label: "Payment date",
      id: "paymentDate",
      field: (booking) => {
        return (
          booking.checkoutInfo?.payments
            .map(
              (payment) =>
                payment.date && moment.utc(payment.date).format("MMM D, YYYY")
            )
            .filter(Boolean)
            .join(", ") || "-"
        );
      },
      isHidden: false,
    },
    {
      label: "Booking status",
      field: "status",
      id: "status",
      isHidden: false,
    },
    {
      label: "Commission amount",
      id: "commissionAmount",
      field: (booking) => {
        const price = getBookingPrice(booking?.checkoutInfo);
        return (+price?.affiliateCommissionAmount || 0) / 100;
      },
      isHidden: false,
    },
  ];

  return ALL_COLUMNS;
};
