<template>
  <div class="edit-affiliate-page">
    <BackTitle confirm-click @click="goBack"> Edit affiliate </BackTitle>
    <Loader v-if="isLoading" class="mx-auto" color="primary" size="m" />
    <Guard
      v-else-if="affiliate"
      permission="affiliates.edit"
      v-slot="{ isAvailable }"
    >
      <AffiliateForm
        :value="affiliate"
        :is-submitting="isSubmitting"
        has-delete-button
        :is-disabled="!isAvailable"
        @submit="save"
      />
    </Guard>
    <div>
      <div class="booking-form-header mb-16">
        <Title :level="2">Bookings</Title>
        <Button
          variant="secondary"
          is-small
          is-outlined
          :is-loading="isExporting"
          @click="handleBookingExport"
        >
          Raw export to CSV
        </Button>
      </div>
      <Table
        name="bookingsAffiliatesTable"
        :columns="tableColumns"
        :rows="bookings"
        has-settings
        @on-view-click="handleOpenBooking"
      >
        <template #footer>
          <infinite-loading
            class="grid-2__full-item"
            :identifier="infiniteId"
            @infinite="infiniteHandler"
          >
            <Loader slot="spinner" class="mx-auto" color="primary" size="m" />
            <div class="empty" slot="no-results">
              {{ $t("You haven't created bookings with this affiliate yet") }}
            </div>
            <div slot="no-more"></div>
          </infinite-loading> </template
      ></Table>
    </div>
  </div>
</template>

<script>
import Table from "@/components/table/Table.vue";
import BackTitle from "@/components/common/BackTitle.vue";
import Guard from "@/components/common/Guard.vue";
import AffiliateForm from "@/components/affiliates/AffiliateForm.vue";
import { mapActions, mapState } from "vuex";
// import BookingsTable from "@/components/BookingsTable/BookingsTable.vue";
import dialog from "@/plugins/dialog";
import { getBookingsAffiliatesTableColumns } from "@/helpers/bookingsAffiliatesTable";
// import { BookingFilterEnum } from "@/helpers/enums";

export default {
  name: "EditAffiliatePage",
  components: { AffiliateForm, Guard, BackTitle, Table },
  data() {
    return {
      isSubmitting: false,
      isExporting: false,
      isLoading: false,
      limit: 10,
      infiniteId: +new Date(),
      isBookingsFetchedOnce: false,
    };
  },
  computed: {
    ...mapState({
      affiliate: (state) => state.affiliates.editedItem,
      bookings: (state) => state.affiliates.bookings,
      venue: (state) => state.venues.selectedVenue,
      bookingsAffiliatesTableColumns: (state) =>
        state.users.venueUserJunction?.bookingsAffiliatesTableColumns,
      // activeFilter: (state) => state.bookings.activeFilter,
    }),
    tableColumns() {
      return getBookingsAffiliatesTableColumns(this.venue);
    },
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchAffiliateById(this.$route.params.id);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      updateAffiliate: "affiliates/updateAffiliate",
      fetchAffiliateById: "affiliates/fetchAffiliateById",
      fetchAffiliateBookings: "affiliates/fetchAffiliateBookings",
      exportBookingsToCSV: "affiliates/exportBookingsToCSV",
    }),
    async save({ id, ...data }) {
      try {
        this.isSubmitting = true;
        await this.updateAffiliate({ id, data });
        this.goBack();
      } finally {
        this.isSubmitting = false;
      }
    },
    async infiniteHandler($state) {
      try {
        const options = {
          affiliateId: this.$route.params.id,
          limit: this.limit,
          isInitial: !this.isBookingsFetchedOnce,
        };
        const len = await this.fetchAffiliateBookings(options);
        if (len) {
          $state.loaded();
          if (len < this.limit) {
            $state.complete();
          }
        } else {
          $state.complete();
        }
      } catch (e) {
        $state.complete();
      } finally {
        this.isBookingsFetchedOnce = true;
      }
    },
    // handleTableSort() {
    //   if (this.activeFilter === BookingFilterEnum.UPCOMING) {
    //     this.SET_ACTIVE_FILTER(null);
    //   }
    //   this.resetBookings();
    //   this.infiniteId += 1;
    // },
    async handleBookingExport() {
      try {
        const isConfirmed = await dialog.confirm({
          title: "Are you sure?",
          message:
            "This action will export ALL bookings. \n This might take some time.",
          okText: "Yes, continue",
          cancelText: "Cancel",
        });
        if (!isConfirmed) {
          return;
        }
        try {
          this.isExporting = true;
          await this.exportBookingsToCSV();
        } catch (err) {
          console.error(err);
        } finally {
          this.isExporting = false;
        }
      } catch (e) {
        console.log(e);
      }
    },
    goBack() {
      this.$router.push({
        name: "Affiliates",
      });
    },
    handleOpenBooking(id) {
      this.$router.push({
        name: "BookingDetail",
        params: {
          id: id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.booking-form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.edit-affiliate-page {
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;
}
</style>
